
<template>
  <v-dialog v-model="localDialog" max-width="400px" transition="dialog-top-transition" persistent>
    <v-card>
      <v-card-title class="mx-auto primary2 py-1 font-size16 text-color-white">{{$t('change')}} {{$t('status')}}</v-card-title>
      <v-card-text class="py-5">{{$t('changeStatusQuestion')}}</v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="confirmToggleStatus" color="primary2" class="text-color-white">{{ $t('yes') }}</v-btn>
        <v-btn @click="cancelToggleStatus">{{ $t('no') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: ['dialog', 'userToggle'],
  data () {
    return {
    localDialog: this.dialog,
      localUserToggle: this.userToggle,
    };
  },
  methods: {
    async getPatients () {
      const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
      await this.$store.dispatch('hospitals/getRelatedPatientsV4', hospitalIdFromStore).then(() => { this.loading = false; });
      },
    async confirmToggleStatus () {
      const requestData = {
        userId: this.localUserToggle.id,
        statusValueToSet: this.localUserToggle.status ? 0 : 1,
      };
      this.$store.dispatch('users/setUserStatus', requestData).then(res => {
        this.localDialog = false;
        this.getPatients();
      });
    },
    cancelToggleStatus () {
      this.localDialog = false;
      this.localUserToggle = null;
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
    }),
  },
  watch: {
    dialog (val) {
      this.localDialog = val;
    },
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
  },
};
</script>
